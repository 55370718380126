import React, { memo } from 'react'
import Layout from 'components/Layout/Layout'
import Banner from 'components/Banner/Banner'
import CustomDevelopment from 'components/CustomDevelopment/CustomDevelopment'
import styled from 'styled-components'
import CaseStudySlider from 'components/CaseStudySlider/CaseStudySlider'
import BuildApplication from 'components/BuildApplication/BuildApplication'
import SEO from 'components/Seo'
import { ApplicationDevProps } from 'components/Props/application-development'
import { md } from 'config/variables'
import { graphql } from 'gatsby'
import { images } from 'config/images'

import CustomerReviewSlider from 'components/CustomerReviewSlider/CustomerReviewSlider'
import DevelopSoftAgile from 'components/DevelopSoftAgile/DevelopSoftAgile'
import IndustriesServe from 'components/IndustriesServe/IndustriesServe'
import AwardsRecognition from 'components/AwardsRecognition/AwardsRecognition'
import FaqSection from 'components/FaqSection/FaqSection'
import FeatureResources from 'components/FeatureResources/FeatureResources'
import { useAnimationClass } from 'components/hooks/useAnimationClass'
import ProcessFlow from 'components/ProcessFlow/ProcessFlow'
import Serviceofferingssection from 'components/Serviceofferingssection/Serviceofferingssection'
import Aboutprojectform from 'components/Aboutprojectform/Aboutprojectform'
import SimformGuaranteeSec from 'components/SimformGuaranteeSec/SimformGuaranteeSec'

export const ApplicationDevPage = styled.div`
  .banner {
    .banner-content {
      max-width: 880px;
      p {
        max-width: 830px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }
  }
  .feature-resource-section {
    background-image: linear-gradient(to top, #eef2ff, #fff);
    .contact-info-card {
      display: none;
    }
  }
  .developsoft-section {
    ${md(`
      display: none;
    `)}
  }
  .industries-service {
    ${md(`
      display: none;
    `)}
  }
  .getintouch-section {
    .awards-recognition-blk {
      ${md(`
        display: none;
      `)}
    }
  }
`

const ApplicationDev = memo(props => {
  const { data } = props
  const { location } = props
  // const { showContent } = useLazyLoad('.lazy-load-div')
  useAnimationClass({ showContent: true })

  let processFlowItem = [
    props.data.process1.childImageSharp.fluid,
    props.data.process2.childImageSharp.fluid,
    props.data.process3.childImageSharp.fluid,
    props.data.process4.childImageSharp.fluid,
    props.data.process5.childImageSharp.fluid,
  ]
  let posterImage = [props.data.posterThumbImage.childImageSharp.fluid]
  let offerings = [
    props.data.offeringsWebapp.childImageSharp.fluid,
    props.data.offeringsMobileApp.childImageSharp.fluid,
    props.data.offeringsOtherApp.childImageSharp.fluid,
  ]
  return (
    <Layout
      mainClass="application-dev services-page"
      location={location}
      data={{
        img: images.contactKeeleyImg,
        img2x: images.contactKeeleyImg2x,
        name: 'Keeley Byrnes',
        description:
          'Helping businesses to accelerate growth by creating modern applications.',
      }}
    >
      <SEO
        title="Application Development Services | Web & Mobile Apps"
        description="Simform offers award-winning App Development services that place you ahead of the competition. We have 10+ years of experience & served 500+ clients. Certified partners of Google, and Amazon."
        keywords={[`simform`]}
        links={[
          {
            href: 'https://www.simform.com/services/application-development/',
            rel: 'canonical',
          },
        ]}
        meta={[
          {
            property: 'og:url',
            content:
              'https://www.simform.com/services/application-development/',
          },
          {
            property: 'og:image',
            content: data && data.bannerImage.childImageSharp.fluid.src,
          },
        ]}
      />
      <ApplicationDevPage>
        <Banner
          bannerData={ApplicationDevProps}
          showBannerImage={true}
          {...props}
        />
        <div className="lazy-load-div">
          <BuildApplication buildApplicationData={ApplicationDevProps} />
          <Serviceofferingssection
            ServiceofferingsData={ApplicationDevProps.serviceofferingsProps}
            items={offerings}
          />
        </div>
        <CaseStudySlider caseStudydata={ApplicationDevProps} {...props} />
        <CustomDevelopment
          customServicedata={ApplicationDevProps.customDevProps}
        />
        <ProcessFlow
          ProcessFlowData={ApplicationDevProps.workingBenefitsProps}
          items={processFlowItem}
        />
        <DevelopSoftAgile developSoftAgiledata={ApplicationDevProps} />
        <IndustriesServe industriesServedata={ApplicationDevProps} />
        <Aboutprojectform
          AboutprojectformData={ApplicationDevProps.AboutprojectformProps}
        />
        <CustomerReviewSlider
          customReviewSliderdata={ApplicationDevProps}
          {...props}
        />
        <AwardsRecognition awardsRecognitiondata={ApplicationDevProps} />
        <SimformGuaranteeSec
          SimformGuaranteeProps={ApplicationDevProps.SimformGuaranteeData}
          posterImage={posterImage}
        />
        <FaqSection faqSectiondata={ApplicationDevProps} />
        <FeatureResources
          featureresourceData={ApplicationDevProps}
          {...props}
        />
      </ApplicationDevPage>
    </Layout>
  )
})

export default ApplicationDev

export const query = graphql`
  query {
    bannerImage: file(
      relativePath: { regex: "/application-dev-banner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 1035) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case1: file(relativePath: { regex: "/case-onlymob@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case2: file(relativePath: { regex: "/case-safari@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case3: file(relativePath: { regex: "/case-ipad@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case4: file(relativePath: { regex: "/case-mobile@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    case5: file(relativePath: { regex: "/redbull-case@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 544) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication1: file(
      relativePath: { regex: "/blog-messaging-app@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication2: file(
      relativePath: { regex: "/blog-like-airbnb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dvdReplication3: file(
      relativePath: { regex: "/blog-healthcare-app-dev@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 360) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    servicebenefit: file(
      relativePath: { regex: "/web-app-benefit-banner@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 771) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    olivia: file(relativePath: { regex: "/olivia.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    justin: file(relativePath: { regex: "/justin.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    ritwik: file(relativePath: { regex: "/ritwik.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    danielle: file(relativePath: { regex: "/danielle.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jawann: file(relativePath: { regex: "/jawann.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jansen: file(relativePath: { regex: "/jansen.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    jeff: file(relativePath: { regex: "/Jeff.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    andy: file(relativePath: { regex: "/andy.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 375) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process1: file(relativePath: { regex: "/ill-ITarchitecture@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process2: file(relativePath: { regex: "/ill-reducerisk@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process3: file(relativePath: { regex: "/ill-rapidapplication@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process4: file(relativePath: { regex: "/ill-microservices@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    process5: file(relativePath: { regex: "/ill-yourgoals@2x.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 320) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    posterThumbImage: file(relativePath: { regex: "/poster-img.png/" }) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 700) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    offeringsWebapp: file(
      relativePath: { regex: "/offerings-webapp-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 524) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    offeringsMobileApp: file(
      relativePath: { regex: "/offerings-mobileapp-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 524) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    offeringsOtherApp: file(
      relativePath: { regex: "/offerings-otherapp-thumb@2x.png/" }
    ) {
      childImageSharp {
        fluid(quality: 99, maxWidth: 524) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
