import React from 'react'
import { AwardsRecognitionSec } from './AwardsRecognition.styles'
import { useSlider } from 'components/hooks/useSlider'

const AwardsRecognition = ({ awardsRecognitiondata }) => {
  const { awardsRecognitionProps } = awardsRecognitiondata
  let selector = 'awards-block-listing-wrap'
  let wrapper = 'awards-block-listing'
  let scroll = 'indicator-container'
  let scrollIndecator = `indicator-inner`

  useSlider({ selector, wrapper, scroll, scrollIndecator, speed: 5 })

  return (
    <AwardsRecognitionSec>
      <div className="heading-wrapper">
        <h2>{awardsRecognitionProps.heading}</h2>
      </div>
      <div className={selector}>
        <div className={wrapper}>
          {awardsRecognitionProps.awardBlockProps.map((awardBlockProps, i) => {
            return (
              <div key={i} className="award-block-wrap">
                <div className="award-block">
                  <div className="award-img-wrapper">
                    {awardBlockProps.awardBlockImg && (
                      <img src={awardBlockProps.awardBlockImg} alt="" />
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="indicator-container">
        <div className="indicator-inner"></div>
      </div>
    </AwardsRecognitionSec>
  )
}

export default AwardsRecognition
